import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type ArrowRightProps = {
    className?: string;
};

const ArrowRight: FunctionComponent<ArrowRightProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'arrow-right'}
            viewBox="-8.5 0 32 32"
        >
            <path d="M1 0c.3 0 .6.1.8.3l13 15c.3.4.3.9 0 1.3l-13 15c-.4.4-1 .5-1.4.1-.4-.4-.5-1-.1-1.4L12.6 16 .2 1.7C-.2 1.3-.1.7.3.3.5.1.8 0 1 0z" />
        </svg>
    );
};
export default ArrowRight;
