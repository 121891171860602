import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type ArrowLeftProps = {
    className?: string;
};

const ArrowLeft: FunctionComponent<ArrowLeftProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'arrow-left'}
            viewBox="-8.5 0 32 32"
        >
            <path d="M14 32c-.3 0-.6-.1-.8-.3l-13-15c-.3-.4-.3-.9 0-1.3l13-15c.4-.4 1-.5 1.4-.1.4.4.5 1 .1 1.4L2.3 16l12.4 14.3c.4.4.3 1-.1 1.4-.2.2-.4.3-.6.3z" />
        </svg>
    );
};
export default ArrowLeft;
